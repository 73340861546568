html{
    background-color: 'rgb(200,200,200)';
}
.item {
    width: 40rem;
    height:70rem;
    color: 'white';
    background-color: 'blue';
}

.container {
    width: 50rem;
    height:80rem;
    color: 'white';
    background-color: 'gray';
}